html {
	background-color: $white;
}

html, body {
	height: 100%;
	display: flex;
	flex-direction: column;
}

body {
	display: flex;
	flex-direction: column;
}

img {
	align-self: center;
	max-width: 100%;
}

.content {
	flex: 1 0 auto;
	padding: 15px 0;

	& ul li {
		list-style: disc;
		margin-left: 15px;
	}
}

.base li,
.base p,
.base h3,
.base h4 {
	color: $font-color;
}

small {
	display: block;
}