$block: '.header';

#{$block} {
	background: $white;
	width: 100%;

	&-logo {
		display: block;
		margin-left: auto;
		max-width: 90%;
		padding: 10px 0;
		position: relative;
		z-index: 1000;

		&Link {
			display: inline-block;
		}

		&Bg {
			background-color: rgba(210, 210, 210, 0.3);
			height: 300px;
			position: absolute;
			transition: 0.4s cubic-bezier(1, -1, 0, 2);
			width: 95%;;
			clip-path: polygon(0% 0%, 50% 100%, 100% 0);
		}

		&Wrapper {
			margin: 0 auto;
			position: relative;
			text-align: center;
			width: 300px;
		}
	}

	&-wrapper {
		display: flex;
	}

	&-phone {
		justify-content: flex-end;
	}

	&-phoneTitle,
	&-brochureTitle {
		display: inline-block;
		font-size: 20px;
	}

	&-phoneNumber,
	&-brochureNumber {
		color: $background-color;
	}

	&-nav {
		background-color: $primary-color;
		position: relative;
		width: 100%;
		z-index: 100;

		&Wrapper {
			display: inline-block;
			width: 100%;
		}

		&List {
			margin: 0;
			padding: 0;
			text-align: center;

			&Item {
				display: inline-block;
				transition: all 300ms ease-in-out;

				&--brochure {
					background-color: $special-color;

					&:hover {
						background-color: $special-color-dark;
					}
				}

				&--phone {
					background-color: $tri-color-dark;

					&:hover {
						background-color: $tri-color;
					}
				}

				&:hover .header-navListItemLink {
					transition: all 300ms ease-in-out;
					background-color: $secondary-color;
					color: $white;
				}

				&--active {
					background-color: $secondary-color;
				}

				&Icon {
					&--home {
						background: url(../images/Home-nav.png) no-repeat;
						display: inline-block;
						height: 25px;
						vertical-align: middle;
						width: 25px;
					}

					&--about {
						background: url(../images/About-nav.png) no-repeat;
						display: inline-block;
						height: 25px;
						vertical-align: middle;
						width: 25px;
					}

					&--stairlifts {
						background: url(../images/stairlifts-nav.png) no-repeat;
						display: inline-block;
						height: 25px;
						vertical-align: middle;
						width: 25px;
					}

					&--mobility {
						background: url(../images/mobility-scooters-nav.png) no-repeat;
						display: inline-block;
						height: 25px;
						vertical-align: middle;
						width: 25px;
					}

					&--beds {
						background: url(../images/adjustable-beds-nav.png) no-repeat;
						display: inline-block;
						height: 25px;
						vertical-align: middle;
						width: 25px;
					}

					&--chairs {
						background: url(../images/rise-recline-chairs-nav.png) no-repeat;
						display: inline-block;
						height: 25px;
						vertical-align: middle;
						width: 25px;
					}
				}

				&Link {
					color: $white;
					display: inline-block;
					font-family: 'Arial', sans-serif;
					font-size: 16px;
					padding: 20px 10px;

					&--active {
						color: $white;
					}

					&--brochure {
						background-color: $special-color !important;

						&:hover {
							background-color: $special-color-dark !important;
						}
					}

					&--phone {
						background-color: $tri-color-dark !important;

						&:hover {
							background-color: $tri-color !important;
						}
					}
				}
			}
		}
	}


	// for mobile devices
	@media #{$small-and-down} {
		&-phone,
		&-brochure {
			font-size: 13px;
		}

		&-logo {
			&Bg {
				background-color: transparent;
			}
		}

		&-phoneTitle,
		&-brochureTitle {
			padding-top: 5px;
		}

		&-phoneNumber,
		&-brochureNumber {
			font-size: 14px;
		}
	}

	// for tablet devices
	@media #{$medium-and-up} {
		&-phoneTitle,
		&-brochureTitle {
			padding-top: 35px;
		}

		&-phoneNumber,
		&-brochureNumber {
			font-size: 20px;
		}
	}

	// for desktop devices
	@media #{$large-and-up} {
		&-phoneTitle,
		&-brochureTitle {
			padding-top: 40px;
		}

		&-phoneNumber,
		&-brochureNumber {
			font-size: 28px;
		}
	}

}