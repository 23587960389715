$block: '.content';

#{$block} {

	&-homeTop,
	&-aboutBottom {
		background-color: $sub-background;
		padding: 50px 0;
	}

	&-homeBottom {
		border-bottom: 10px solid $sub-background;
		border-top: 10px solid $sub-background;

		&Content {
			background-color: $background-color;
			border: 10px solid $white;
			border: 10px solid $background-color;
			box-shadow: inset 0 0 0 4px $line-color;
			box-shadow: inset 0 0 0 4px $line-color;
			color: $white;
			min-height: 350px;
			padding: 10px;

			& p {
				font-size: 14px;
			}

			& h5 {
				font-weight: bold;
			}
		}
	}

	&-call-us {
		background-image: url("../images/athena-mobility-call-us.png");
		background-repeat: no-repeat;
		background-position: right;
		background-size: 75%;

		&-text {
			width: 600px;
		}
	}

	&-images {
		border: 1px solid $line-color;
		max-height: 300px;
	}

	&-homeMiddle {
		border-bottom: 10px solid $sub-background;
		margin-bottom: 50px;
		padding: 50px 0;
	}

	&-homeBottom {
		& p {
			font-size: 12px;
		}

		& img {
			max-width: 100%;
		}
	}

	&-bed {
		&Collection {
			margin: 10px;
		}

		&MiddleBottom {
			background-color: $sub-background;
			padding: 50px 0;
		}
	}

	&-delivery {
		&Title {
			background-color: $sub-background;
			padding: 10px 0;
		}
	}

	&-vat {
		&Title {
			color: $white;
			margin-top: -70px;
			padding: 0 15px;
			text-align: center;

			&Sub {
				background-color: $sub-background;
				padding: 10px 5px;
			}
		}
	}

	&-bed {
		&Bg {
			background-color: $sub-background;
			margin: 20px 0;
			padding: 30px 0;

			&--header {
				background-color: $sub-background-dark;
				padding: 10px;
			}

			&--content {
				background-color: $sub-background;
				min-height: 150px;

				& p {
					margin: 0 !important;
				}
			}
		}
	}

	&-emergency {
		&Bg {
			background-color: $sub-background;
			margin: 20px 0;
			padding: 30px 0;

			&--header {
				background-color: $sub-background-dark;
				padding: 10px;
			}

			&--content {
				background-color: $sub-background;
				min-height: 210px;

				& p {
					margin: 0 !important;
				}
			}
		}
		&Box {
			font-size: 1.4rem;
			font-weight: bold;
			margin-top: 10px;
		}
	}

	&-chair {
		&Bg {
			background-color: $sub-background;
			margin: 20px 0;
			padding: 30px 0;

			&--header {
				background-color: $sub-background-dark;
				padding: 10px;
			}

			&--content {
				background-color: $sub-background;
				min-height: 210px;

				& p {
					margin: 0 !important;
				}
			}
		}

		&Box {
			border: 5px solid $secondary-color;
			box-shadow: 0 0 0 10px $primary-color;
			border-radius: 1px;
			margin-top: 10px;
		}

		&Collection {
			padding: 20px !important;
		}

		&Line {
			color: $line-color;
			width: 300px;
		}
	}

	&-price {
		&Image {
			max-width: 50%;
		}
	}

	// for mobile devices
	@media #{$small-and-down} {
		&-homeBottomContent {
			min-height: inherit;
		}

		&-call-us {
			background-image: none;

			&-text {
			}
		}

		&-vat {
			&Title {
				font-size: 25px;
				margin-top: -40px;
				text-align: center;
			}
		}
	}


	// for tablet devices
	@media #{$medium-and-up} {
		&-call-us {
		}
	}


	// for desktop devices
	@media #{$large-and-up} {
		&-call-us {
		}
	}
}

