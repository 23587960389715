html {
	font-family: 'Fahkwang', sans-serif;
	font-weight: normal;
}

a {
	font-weight: bold;
}

p {
	font-size: 16px;
}

strong {
	font-weight: 700;
}