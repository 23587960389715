$block: '.voucher';
$voucher-theme-color: #295d32;
$voucher-theme-color-dark: darken($voucher-theme-color, 15%) !default;

.modal-voucher .modal-close {
	color: $white;
}

.black-friday {
	#{$block} {
		&-box {
			&--blackFriday {
				box-shadow: 0 0 0 5px #fafafa;
				background: $voucher-theme-color;
			}

			& p,
			& strong,
			& small {
				color: $white;
			}
		}

		&-title,
		&-description,
		&-countdown {
			color: $white;
		}
	}
}

#{$block} {

	&-button {
		&--price {
			background: $voucher-theme-color;
			border: 3px solid $voucher-theme-color;
			box-shadow: 0 0 0 $voucher-theme-color,0 5px 0 $voucher-theme-color-dark ,0 5px 0 $voucher-theme-color-dark ;

			&:hover {
				color: $voucher-theme-color-dark;
			}

			&:active {
				box-shadow: 0 1px 0 $voucher-theme-color, 0 2px 0 $voucher-theme-color-dark , 0 2px 2px $voucher-theme-color-dark ;
			}

			&:focus {
				color: $white;
				background: $voucher-theme-color;
			}
		}
		&--phone {
			background: $tri-color-dark;
			border: 3px solid $tri-color-dark;
			box-shadow: 0 0 0 $tri-color-dark,0 5px 0 $tri-color ,0 5px 0 $tri-color ;

			&:hover {
				color: $tri-color;
			}

			&:active {
				box-shadow: 0 1px 0 $tri-color-dark, 0 2px 0 $tri-color , 0 2px 2px $tri-color ;
			}

			&:focus {
				color: $white;
				background: $tri-color-dark;
			}
		}
	}

	&-box {
		box-shadow: 0 0 0 5px $secondary-color;
	}

	&-image {
		width: 100%;

		&--amazon {
			width: initial;
		}
	}

	&-title {
		font-size: 2.5rem;
		padding-top: 20px;
		display: inline-block;

		& strong {
			font-size: 3rem;
		}
	}

	&-description {
		font-size: 2rem;
	}

	// for mobile devices
	@media #{$small-and-down} {
		&-claim {
			font-size: 2rem;
		}

		&-desc {
			display: none;
		}
	}


	// for tablet devices
	@media #{$medium-and-up} {
	}


	// for desktop devices
	@media #{$large-and-down} {
		.modal-voucher {
			width: 80% !important;
			max-height: 90% !important;
		}

		.voucher {
			&-image {
				max-width: 30%;

				&--amazon {
					width: 40%;
				}
			}

			&-title {
				font-size: 1.8rem;
			}

			&-description {
				font-size: 1.2rem;
			}
		}
	}
}