::selection {
	background: $primary-color;
	color: $white;
	text-shadow: 0 0 0 $white;
}

::-moz-selection {
	background: $primary-color;
	color: $white;
	text-shadow: 0 0 0 $white;
}

.row {
	margin-bottom: 10px;
}

.modal {
	background-color: #fff;
	top: 5% !important;
	width: 60%;
	max-height: 90%;
}

#mce-success-response {
	color: #536b15 !important;
	font-size: 1.5rem;
	font-weight: bold;
}

.modal-close-button {
	font-size: 50px;
	position: absolute;
	right: 20px;
}

footer .email {
	color: $white;
}

.dropdown-content {
	z-index: 11111111;
}

.input-field input {
	padding: 0 10px !important;
}

.input-field {
	margin-bottom: 0;
}

label.error {
	color: $error-color !important;
	font-weight: bold;
	left: 0 !important;
	position: relative !important;
}

label.valid {
	left: 0 !important;
	position: relative !important;
}

.brochure-option {
	display: inline-block;
	padding: 10px 0;
}

.content form ul li {
	list-style-type: none !important;
	margin-left: 0;
}

#mce-error-response,
#mce-responses small {
	display: inline-block;
}

.modal-brochure-feedback {
	max-width: 650px !important;
}

.modal-request {
	max-height: 100%;
	width: fit-content !important;
}

.secondary-color {
	color: $secondary-color;
}

.no-disc li {
	list-style: none !important;
	padding-bottom: 20px;
}

.feedback-success {
	text-align: center;
	padding: 20px;
}

.modal-brochure {
	max-width: 1200px !important;
}

.brochure-parent {
	display: flex;
	position: relative;
}

.brochure-child {
	display: inline-block;
	padding: 0 15px;
	width: 40%;
}

.request-brochure,
.brochure-right {
	border: 20px solid #77A8AB;
}

.brochure-submit {
	width: 100%;
}

.brochure-right {
	background: url("https://athenamobility.co.uk/user/themes/athenamobility/images/athena-mobility-brochure-new.png") no-repeat;
	width: 100%;
	margin-top: 80px;
	background-size: cover;
	background-position: center;
}

.request-brochure-right {
	background: url("https://athenamobility.co.uk/user/themes/athenamobility/images/athena-mobility-brochure-new.png") no-repeat;
	width: 100%;
	background-size: cover;
	background-position: center;
}

.request-callback {
	background: url("https://athenamobility.co.uk/user/themes/athenamobility/images/athena-mobility-call-us.png") no-repeat;
	width: 100%;
	background-size: cover;
	background-position: top center;
}

.mce_inline_error {
	color: #ed7777 !important;
}

.brochure-title {
	position: absolute;
	top: 45px;
	color: #616667;
	font-size: 1.7rem;
}

.request-brochure-title {
	color: #616667;
	font-size: 1.7rem;
}

.image-modifier--50 {
	width: 50%;
}

.emergency-voucher {
	width: 70% !important;
}

.emergency-h3 {
	padding: 10px;
}

.brochure-more-options {
	padding-top: 20px;
	display: inline-block;
}

// for mobile devices
@media #{$small-and-down} {

	.brochure-title {
		position: initial;
		font-size: 2rem !important;
	}

	.modal .modal-content {
		padding: 10px;
	}
	.brochure-more-options {
		width: 100%;
		text-align: center;
	}
	.brochure-option {
		padding: 10px 20px;
	}

	.brochure-parent {
		display: block;
		position: relative;
	}

	.brochure-right,
	.request-callback,
	.request-brochure {
		width: 100%;
		height: 100%;
		position: absolute;
		filter: opacity(0.1) brightness(0.6);
		background-size: cover;
		padding: 0;
		margin: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.phone-claim {
		font-size: .7rem;
	}

	.modal {
		top: 0 !important;
		width: 100%;
		max-height: 80%;
	}

	.modal-request {
		width: 90%;
	}

	.modal-close-button {
		right: 10px;
		font-size: 30px;
	}

}

@media #{$medium-and-down} {

	.brochure-title {
		font-size: 1.6rem;
		top: 30px;
	}

	.brochure-child {
		width: 100%;
	}

	.modal-brochure {
		width: 100%;
	}

	.s-align-center {
		text-align: center;
	}

	.phone-claim {
		font-size: .8rem;
	}
}


@media #{$large-and-up} {
	.brochure-title {
		font-size: 2.7rem;
		top: 45px;
	}

	.modal {
		width: 90%;
	}
}

@media #{$large-and-up} {
	.brochure-title {
		font-size: 3.2rem;
		top: 10px;
	}
}

@media #{$extra-large-and-up} {
	.modal-request {
		width: 40%;
	}
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {

	.brochure-title {
		font-size: 1.8rem !important;
	}
}

@media only screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.modal {
		width: 90%;
	}
}
//
//.ro-select-text {
//	border: none;
//	border-bottom: 1px solid #9e9e9e;
//	display: block;
//	max-height: 48px;
//	padding: 16px 24px 8px 10px;
//	cursor: pointer;
//}
//.ro-select-text.ro-select-text-empty {
//	color: #9e9e9e !important;
//}
//
//.ro-select-caret {
//	position: absolute;
//	right: 8px;
//	top: 20px;
//	color: #888888;
//}
//.ro-select-list {
//	user-select: none;
//	position: absolute;
//	transition: 0.25s ease;
//	top: 0;
//	left: 0;
//	width: 100%;
//	display: none;
//	list-style: none;
//	margin: 0;
//	padding: 8px 0;
//	background-color: white;
//	box-shadow: 0 3px 10px #adadad;
//	z-index: 11111;
//}
//.ro-select-list > .ro-select-item {
//	padding: 0 16px;
//	line-height: 48px;
//	cursor: pointer;
//}
//.ro-select-list > .ro-select-item:hover {
//	background-color: #eaeaea;
//}
//.ro-select-list > .ro-select-item.ro-select-item-active {
//	background: #eaeaea;
//}
