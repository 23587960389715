$block: '.sale';

#{$block} {
	background-color: $sub-background;
	color: $secondary-color;
	display: flex;
	width: 100%;
	position: relative;
	z-index: 90;

	&-left,
	&-right {
		display: flex;
		align-items: center;
	}

	&-left {
		justify-content: flex-start;
	}

	&-right {
		justify-content: flex-end;
	}

	&-claim {
		margin: 5px 0 0 0;
		color: $line-color;
	}

	&-icon {
		display: inline-block;
		margin: 0 10px;
	}

	// for mobile devices
	@media #{$small-and-down} {
		&-claim {
			font-size: 2rem;
		}
		
		&-desc {
			display: none;
		}
	}


	// for tablet devices
	@media #{$medium-and-up} {
	}


	// for desktop devices
	@media #{$large-and-up} {
	}
}