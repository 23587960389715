$block: '.footer';

#{$block} {
	background-color: $background-color;
	padding-top: 30px;

	& p {
		color: $white;
		font-weight: bold;
		font-size: 1.2rem;
	}

	& img {
		vertical-align: middle;
	}

	&-top {
		color: $white;

		&Link {
			color: $white;
			display: block;
			font-weight: normal;
			font-size: .9rem;
			padding: 10px 0;

			&:hover {
				color: $primary-color;
			}
		}

		& h6 {
			font-weight: bold;
		}

	}

	&-bottom {
		color: $white;

		&Facebook {
			color: $white;

			&:hover {
				color: $primary-color;
			}
		}

		&Phone,
		&Brochure,
		&Mail {
			color: $white;
			font-size: 20px;

			&:hover {
				color: $primary-color;
			}
		}

		&Print {
			color: $white;
			font-size: .7rem;
		}
	}

	// for mobile devices
	@media #{$small-and-down} {
	}

	// for tablet devices
	@media #{$medium-and-up} {
	}

	// for desktop devices
	@media #{$large-and-up} {
	}
}