$module: '.button';

#{$module} {
	background: $primary-color;
	border: 3px solid $primary-color;
	border-radius: 7px;
	box-shadow: 0 0 0 $primary-color,0 5px 0 $primary-color-dark,0 5px 0 $primary-color-dark;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
	margin: 5px;
	padding: 10px 20px;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-shadow: none;
	transition: all 150ms ease;
	-webkit-transition: all 150ms ease;

	&:hover {
		background: transparent;
		color: $primary-color;
		text-decoration: none;
	}

	&--slider {
		&:hover {
			color: $white;
		}
	}

	&:active {
		animation: none;
		-webkit-animation: none;
		box-shadow: 0 1px 0 $primary-color, 0 2px 0 $primary-color-dark, 0 2px 2px $primary-color-dark;
		transform: translateY(3px);
		-webkit-transform: translateY(3px);
		-moz-transform: translateY(3px);
	}

	&:focus {
		color: $white;
		background: $primary-color;
	}

	&--price {
		background: $tri-color;
		border: 3px solid $tri-color;
		box-shadow: 0 0 0 $tri-color,0 5px 0 $tri-color-dark,0 5px 0 $tri-color-dark;

		&:hover {
			color: $tri-color;
		}

		&:active {
			box-shadow: 0 1px 0 $tri-color, 0 2px 0 $tri-color-dark, 0 2px 2px $tri-color-dark;
		}

		&:focus {
			color: $white;
			background: $tri-color;
		}
	}

	&--close {
		background: $error-color;
		border: 3px solid $error-color;
		box-shadow: 0 0 0 $error-color,0 5px 0 $error-color-dark,0 5px 0 $error-color-dark;

		&:hover {
			color: $error-color;
			background: $error-color-dark;
		}

		&:active {
			background: $error-color;
			box-shadow: 0 1px 0 $error-color, 0 2px 0 $error-color-dark, 0 2px 2px $error-color-dark;
		}

		&:focus {
			color: $white;
			background: $error-color;
		}
	}

	&-close {
		cursor: pointer;
		font-size: 30px;
		padding: 2px;
		position: absolute;
		right: 10px;
		top: 0;
		z-index: 77;
	}
}